export default function TextInput({
  title,
  onChange,
  value,
  type,
  error,
  required,
}) {
  return (
    <div className="form-group mb-4">
      <label className="text-secondary">
        {title} {required ? <span className="required">*</span> : ""}
      </label>
      <input
        className="form-control"
        type={type}
        onChange={onChange}
        value={value}
      />
      <div
        className="invalid-feedback"
        style={{
          display: "block",
          fontSize: " .7rem",
          color: " #B71D20",
          padding: "5px 0px",
        }}
      >
        {error}
      </div>
    </div>
  );
}
