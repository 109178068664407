"use client";
import Link from "next/link";
import Styles from "@/styles/Breadcrumbs.module.css";

const Breadcrumbs = ({
  pageOne,
  pageOneUrl,
  pageTwo,
  pageTwoUrl,
  pageThree,
  pageThreeUrl,
  activePage,
}) => {
  return (
    <div className="container-fluid">
      <div className="col-md-12 p-0">
        <ul className={`${Styles.breadcrumbs}`}>
          <li className={`${Styles.breadcrumbs__item}`}>
            <Link
              href={pageOneUrl}
              className={`${Styles.breadcrumbs__url}`}
              prefetch={false}
            >
              {pageOne}
            </Link>
          </li>
          {pageTwo && (
            <li className={`${Styles.breadcrumbs__item}`}>
              <Link
                href={pageTwoUrl}
                className={`${Styles.breadcrumbs__url}`}
                prefetch={false}
              >
                {pageTwo}
              </Link>
            </li>
          )}{" "}
          {pageThree && (
            <li className={`${Styles.breadcrumbs__item}`}>
              <Link
                href={pageThreeUrl}
                className={`${Styles.breadcrumbs__url}`}
                prefetch={false}
              >
                {pageThree}
              </Link>
            </li>
          )}
          <li
            className={`${Styles.breadcrumbs__item} ${Styles.breadcrumbs__item__current}`}
          >
            <h1 style={{ fontSize: "1rem" }}>{activePage}</h1>
          </li>
        </ul>
      </div>
    </div>
  );
};
export default Breadcrumbs;
