"use client";
import { signIn } from "next-auth/react";
import Link from "next/link";
import TextInput from "../TextInput";
import { useState } from "react";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useRouter } from "next/navigation";
import Loading from "../Loading";
const Login = () => {
  const router = useRouter();
  const [errors, setErrors] = useState({ email: "", password: "" });
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [loading, setLoading] = useState(false);

  const onSubmitLogin = async (e) => {
    e.preventDefault();
    setErrors({ email: "", password: "" });
    if (!email && !password) {
      setErrors({
        email: "Email field is required!",
        password: "Password field is required!",
      });
    } else if (!email) {
      setErrors({ email: "Email field is required!", password: "" });
    } else if (!password) {
      setErrors({ email: "", password: "Password field is required!" });
    } else {
      setLoading(true);
      const status = await signIn("CredentialsLogin", {
        email: email,
        password: password,
        callbackUrl: "/user/dashboard",
        redirect: false,
      });

      setLoading(false);
      if (!status.ok) {
        toast.error("Login Unauthorized!", {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 3000,
        });
      }
      if (status.ok) {
        router.push("/user/dashboard");
      }
    }
  };
  return (
    <>
      {loading && <Loading />}
      <div className="text-center mb-3  mt-5">
        <h2>
          <strong>Login</strong>
        </h2>

        <button
          type="button"
          onClick={async () => {
            await signIn("google", {
              callbackUrl: "/user/dashboard",
            });
          }}
          className="loginBtn loginBtn--google"
        >
          Login with Google
        </button>
        <button
          type="button"
          onClick={async () =>
            await signIn("facebook", {
              callbackUrl: "/user/dashboard",
            })
          }
          className="loginBtn loginBtn--facebook"
        >
          Login with Facebook
        </button>
      </div>
      <form onSubmit={onSubmitLogin}>
        <p className="text-center">or:</p>

        <TextInput
          title={"Email"}
          onChange={(e) => setEmail(e.target.value)}
          value={email}
          type={"email"}
          error={errors ? errors.email : ""}
          required={true}
        />
        <TextInput
          title={"Password"}
          onChange={(e) => setPassword(e.target.value)}
          value={password}
          type={"password"}
          error={errors ? errors.password : ""}
          required={true}
        />

        {/* <div className="row mb-4">
          <div className="col-md-6 d-flex justify-content-start">
            <div className="checkbox-input-custom-design ">
              <input type="checkbox" />
              <span>Remember me</span>
            </div>
          </div>

          <div className="col-md-6 d-flex justify-content-end">
            <a href="#!" className="form-link-custom">
              Forgot password?
            </a>
          </div>
        </div> */}

        <div className="row mb-4">
          <div className="col-md-12 d-flex justify-content-center">
            <button
              type="submit"
              className="btn btn-primary-submit btn-block p-2 pl-5 pr-5 "
            >
              Login
            </button>
          </div>
        </div>

        <div className="text-center">
          <p>
            New to TBN24?{" "}
            <Link href={"/register"} className="form-link-custom">
              Create an account
            </Link>
          </p>
        </div>
      </form>
    </>
  );
};

export default Login;
